/** ****** My Swiss Lunch v0.1 ****** */
/** ****** MSL Juin 2021      ****** */
/** *** App.VUE contexte général **** */

/** Affiche le header bar et le routerview  */


<template>
  <div id="app" class="app">
    <body class="Site"> <main class="Site-content">
    <HeaderBar  v-if="!isHomePage" :isHomePage="false" />
    <router-view /></main>
    <footer class="footer">
                    <div class="is-hidden-desktop is-hidden-tablet "> 
      Concocté avec <img style="float:center;max-height:30px" src="./assets/assiette_coeur_mini.png" alt="amour"> en Suisse <br/>par l'équipe My Swiss Lunch © 2021
                  </div>  
                <div class="is-hidden-mobile "> 
      Concocté avec <img style="float:center;max-height:30px" src="./assets/assiette_coeur_mini.png"  alt="amour"> en Suisse par l'équipe My Swiss Lunch © 2021
                </div>
    
<div class="linkadmin"><router-link to="/terms"> Conditions Générales - Cookies et vie privée </router-link> </div>
    </footer>
    </body>
    </div>
</template>


<script>
import HeaderBar from "@/components/header-bar";
export default {
  name: "App",
  components: { HeaderBar },
  computed: {
    isHomePage: function () {
      if (this.$route.path == "/") return true;
      else return false;
    },
  },
};
</script>


<style scoped>
.footer {
  font-size:20px;
  text-align:center;
}
.Site {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.Site-content {
  flex: 1;
}

.linkadmin{
  font-size:10px;
}
</style>

<style lang="scss">
@import "@/design/index.scss";
</style>



