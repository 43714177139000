import Vue from 'vue'
import App from './App.vue'
import Amplify from 'aws-amplify';
import '@aws-amplify/ui-vue';
import aws_exports from './aws-exports';
import router from './router';
import Buefy from 'buefy'
import vueResource from 'vue-resource'

      /* recaptcha v3
 import { VueReCaptcha } from 'vue-recaptcha-v3'
Vue.use(VueReCaptcha, { siteKey: '6LdXiY8aAAAAAIzqRzyjUcVXq3flwk8_4SHaQ3iV'})

*/
import { I18n } from 'aws-amplify';
I18n.setLanguage('fr');

import VueMeta from 'vue-meta'
Amplify.configure(aws_exports);
Vue.use(require('vue-moment'));

/*
Amplify.configure({
  Auth: {
   
    authenticationFlowType: 'CUSTOM_AUTH'
  }
});
*/
Vue.config.productionTip = false;

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})
Vue.use(Buefy, {
  defaultIconPack: 'fas'})
Vue.use(vueResource)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')