/** ****** My Swiss Lunch v0.1 ****** */
/** ****** MSL Juin 2021      ****** */
/** *** Header Bar - Menu principal **** */

/** Affiche les différentes options de menu et liens */


<template>
  <div>
    <header>
      <div class="columns">
        <div v-if="!isHomePage" class="column is-2 is-hidden-mobile">
          <router-link to="/">
            <img
              src="../assets/logov3.png"
              alt="My Swiss Lunch"
              class="logodesktop"
          /></router-link>
        </div>
        <div class="column">
          <template>
            <b-navbar>
              <template #brand>
                <b-navbar-item
                  class="is-hidden-desktop is-hidden-tablet"
                  tag="router-link"
                  :to="{ path: '/' }"
                > 
                  <img src="../assets/logov3.png" style="height:30px;" alt="My Swiss Lunch" />
                </b-navbar-item>
              </template>

              <template #end>
           
                <b-navbar-item
                  v-if="!user"
                  tag="router-link"
                  :to="{ path: '/about' }"
                >
                  Comment ça marche </b-navbar-item
                ><b-navbar-item
                  v-if="!user"
                  tag="router-link"
                  :to="{ path: '/contact' }"
                >
                  Nous contacter
                </b-navbar-item>


          
              </template>
            </b-navbar>
          </template>
        </div>
      </div>
    </header>

  </div>
</template>


<script>

export default {
  name: "headerbar",

  props: ["isHomePage"],
  data() {
    return {
    };
  },
};
</script>