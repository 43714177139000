<template>
<body>



    <section>                  
        <div class="columns">
            <div class="column is-hidden-mobile">

                <img src="../assets/logov3.png" alt="logo my swiss lunch" class="logodesktop">
            </div>

            <div class="column is-one-third is-hidden-mobile has-background-primary">

            </div>
            <div class="column is-half">

<HeaderBar :isHomePage="true"/>

              
            </div>
        </div>


    </section>


    <section>
        <div class="columns">
            <div class="column">

            </div>

            <div class="column is-one-third has-background-primary">
                <h1> Réserver </h1>
                <h3> mon plat du jour </h3>
                
            </div>

            <div class="column is-half ">
                        <h2> {{text}}</h2> <br/> 
       

            </div>
        </div>


    </section>


    <section>
        <div class="columns">
            <div class="column is-hidden-mobile">

                        <br/><br/>
                
                    <img src="../assets/perso.svg" alt="personnages autour de nourriture" style="width:200%;" class="responsive">
              
            </div>

            <div class="column is-one-third has-background-primary">
                        <br/><br/>
                        <div class="container is-fluid">

                <h3> Prochainement, </h3>
                <h3> ouverture </h3>
                <h3> des réservations </h3>

               </div>

            </div>

            <div class="column is-half">
            
                    <img src="../assets/poulet.svg" alt="personnages autour de nourriture"  class="responsive">

                    <div class="is-hidden-mobile">
                   <br /><br />
</div>
                <section>
                        <div class="has-text-right">
</div>


                </section>






            </div>
        </div>


    </section>

</body>

</template>


    <script type="text/javascript">
document.addEventListener('DOMContentLoaded', () => {

        // Get all "navbar-burger" elements
        const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
      
        // Check if there are any navbar burgers
        if ($navbarBurgers.length > 0) {
      
          // Add a click event on each of them
          $navbarBurgers.forEach( el => {
            el.addEventListener('click', () => {
      
              // Get the target from the "data-target" attribute
              const target = el.dataset.target;
              const $target = document.getElementById(target);
      
              // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
              el.classList.toggle('is-active');
              $target.classList.toggle('is-active');
      
            });
          });
        }
      
      });
      
      
import HeaderBar from "@/components/header-bar";

      export default {

  data() {
      return{
    list: ['à proximité', 'à toute heure', 'autour de moi', 'quand j\'en ai envie'],
    index: 0,
    text: '',
    speed: 2000
      }
  },
  
 async  mounted() {
    this.text = this.list[this.index];
    setInterval(this.getTextFromList, this.speed);
    



  },
  async created(){


  },
  components:{HeaderBar},
  methods: {
      

    getTextFromList() {
      var vm = this;
      
      vm.text = vm.list[vm.index];
      vm.index += 1;
      
      if (vm.index >= vm.list.length) {
        vm.index = 0;
      }
    }
  }
      }

      
      </script>